/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "bootstrap-daterangepicker/daterangepicker.css";
import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.bundle";
import { Provider } from "react-redux";
import store from "./redux/store";
import { saveState } from "./redux/localStorage";
import "./assets/styles/app.scss";
import "react-toastify/dist/ReactToastify.css";
import Index from "./pages/Index";

store.subscribe(() => {
  saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <Index />
    <ToastContainer autoClose={3000} />
  </Provider>,
  document.querySelector("#root")
);
