/** @format */

import React from "react";

const SubscriptionCard = ({ value, text, className }) => {
  return (
    <div
      className={`${className || "col-12 col-md-6 col-xl-4 px-0"} px-md-2 px-xl-2 mb-3`}
    >
      <div className="bg-white rounded-lg shadow py-4 px-4 px-md-3 px-lg-4 ads-card">
        <h2 className="font-weight-bold">{value}</h2>
        <p className="text-light ads-details text-uppercase mb-0">{text}</p>
      </div>
    </div>
  );
};

const AdsTextCard = ({ id }) => {
  return (
    <div className="col-6 col-md-4 col-xl-3 mb-3 px-2">
      <div className="rounded ads-card__text shadow-sm">
        <p
          className="small text-center mb-0 d-center px-4 text pointer"
          type="button"
          data-toggle="modal"
          data-target={`#text-ads${id}`}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus rem
          accusamus doloribus animi dicta
        </p>
        <div className="app-dark-50 p-2 d-flex align-items-center justify-content-between">
          <p className="small mb-0">
            Created: <span className="font-weight-bold">12/12/2020</span>{" "}
          </p>
          <p className="mb-0 smal">
            <i className="fas fa-trash-alt text-red"></i>
          </p>
        </div>
      </div>
    </div>
  );
};

const AdsVideoCard = ({ id }) => {
  return (
    <div className="col-6 col-lg-4 col-xl-3 mb-3 px-2">
      <div className="rounded ads-card__video shadow-sm">
        <img
          src="https://image1.masterfile.com/getImage/NzAwLTAyNjYwMDAzZW4uMDAwMDAwMDA=AJaZt5/700-02660003en_Masterfile.jpg"
          alt=""
          className="small text-center mb-0 d-center w-100 video pointer"
          type="button"
          data-toggle="modal"
          data-target={`#video-ads${id}`}
        />

        <div className="app-dark-50 p-2 px-3 d-flex flex-wrap align-items-center justify-content-between">
          <p className="col-12 x-small px-0 mb-1 pb-1 border-bottom_light">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quibusdam
            sit quam, nesciunt iusto temporibus aspernatur
          </p>
          <p className="small mb-0">
            Created: <span className="font-weight-bold">12/12/2020</span>{" "}
          </p>
          <p className="mb-0 smal">
            <i className="fas fa-trash-alt text-red"></i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;

export { AdsTextCard, AdsVideoCard };
