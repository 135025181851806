/** @format */

import React from "react";
import loadable from "@loadable/component";
import Loader from "../components/Loader";

export const Login = loadable(() => import("./auth/Login"), {
  fallback: <Loader />,
});

export const Home = loadable(() => import("./dashboard/Home"), {
  fallback: <Loader />,
});

export const Billboard = loadable(() => import("./dashboard/Billboard"), {
  fallback: <Loader />,
});

export const AuditTrails = loadable(() => import("./dashboard/AuditTrails"), {
  fallback: <Loader />,
});

export const SingleBoard = loadable(() => import("./dashboard/SingleBoard"), {
  fallback: <Loader />,
});

export const Users = loadable(() => import("./dashboard/Users"), {
  fallback: <Loader />,
});

export const SuperUsers = loadable(() => import("./dashboard/SuperUsers"), {
  fallback: <Loader />,
});

export const SingleUser = loadable(() => import("./dashboard/SingleUser"), {
  fallback: <Loader />,
});

export const Sites = loadable(() => import("./dashboard/Sites"), {
  fallback: <Loader />,
});

export const Extensions = loadable(() => import("./dashboard/Extensions"), {
  fallback: <Loader />,
});

export const SiteVisits = loadable(() => import("./dashboard/SiteVisits"), {
  fallback: <Loader />,
});

export const FailedPayments = loadable(
  () => import("./dashboard/FailedPayments"),
  {
    fallback: <Loader />,
  }
);

export const UnconfiguredSSL = loadable(
  () => import("./dashboard/UnconfiguredSSL"),
  {
    fallback: <Loader />,
  }
);

export const PaymentDetails = loadable(
  () => import("./dashboard/PaymentDetails"),
  {
    fallback: <Loader />,
  }
);

export const FailedRegistrations = loadable(
  () => import("./dashboard/FailedRegistrations"),
  {
    fallback: <Loader />,
  }
);

export const RegistrationDetails = loadable(
  () => import("./dashboard/RegistrationDetails"),
  {
    fallback: <Loader />,
  }
);

export const SingleSite = loadable(() => import("./dashboard/SingleSite"), {
  fallback: <Loader />,
});

export const Subscriptions = loadable(
  () => import("./dashboard/Subscriptions"),
  {
    fallback: <Loader />,
  }
);

export const Admins = loadable(() => import("./dashboard/Admins"), {
  fallback: <Loader />,
});

export const EmailPricing = loadable(() => import("./dashboard/EmailPricing"), {
  fallback: <Loader />,
});

export const Coupons = loadable(() => import("./dashboard/Coupons"), {
  fallback: <Loader />,
});

export const Billings = loadable(() => import("./dashboard/Billings"), {
  fallback: <Loader />,
});

export const FreeTrials = loadable(() => import("./dashboard/FreeTrials"), {
  fallback: <Loader />,
});

export const SingleBilling = loadable(
  () => import("./dashboard/SingleBilling"),
  {
    fallback: <Loader />,
  }
);

export const AddAdmin = loadable(() => import("./dashboard/AddAdmin"), {
  fallback: <Loader />,
});

export const AddEmailPricing = loadable(
  () => import("./dashboard/AddEmailPricing"),
  {
    fallback: <Loader />,
  }
);

export const Settings = loadable(() => import("./dashboard/Settings"), {
  fallback: <Loader />,
});

export const Requests = loadable(() => import("./dashboard/Requests"), {
  fallback: <Loader />,
});

export const PendingComments = loadable(
  () => import("./dashboard/PendingComments"),
  {
    fallback: <Loader />,
  }
);

export const SingleRequest = loadable(
  () => import("./dashboard/SingleRequest"),
  {
    fallback: <Loader />,
  }
);

export const UserGuides = loadable(() => import("./dashboard/UserGuides"), {
  fallback: <Loader />,
});

export const Feedbacks = loadable(() => import("./dashboard/Feedbacks"), {
  fallback: <Loader />,
});

export const Plans = loadable(() => import("./dashboard/Plans"), {
  fallback: <Loader />,
});

export const Privacy = loadable(() => import("./dashboard/Privacy"), {
  fallback: <Loader />,
});

export const Referrers = loadable(() => import("./dashboard/Referrers"), {
  fallback: <Loader />,
});

export const Referrals = loadable(() => import("./dashboard/Referrals"), {
  fallback: <Loader />,
});
