/** @format */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PAGES_URL from "./routes";

const UnAuthRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !authenticated ? (
        <Route render={() => <Component {...props} />} />
      ) : (
        <Redirect to={PAGES_URL.HOME} />
      )
    }
  />
);

const mapStatetoProps = (state) => ({
  authenticated: state.user.authenticated,
});

export default connect(mapStatetoProps, null)(UnAuthRoute);
