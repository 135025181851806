/** @format */

import axios from "axios";
import history from "./history";
import { toast } from "react-toastify";
import {
  FETCH_ADMINS,
  FETCH_SINGLE_USER,
  FETCH_SUPER_USERS,
  FETCH_USERS,
  LOADING,
  LOGIN_USER,
  LOGOUT,
} from "../reducers/types";
import PAGES_URL from "../../routes/routes";

const apiBaseUrl = process.env.APP_BASE_URL;
const appToken = localStorage.getItem("Token");

const headers = {
  headers: {
    Authorization: appToken,
  },
};

export const adminLogin = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/auth/admin/login`, data)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { token, ...rest } = res.data;
      localStorage.setItem("Token", `Bearer ${token}`);
      dispatch({ type: LOGIN_USER, payload: rest });
      history.push(PAGES_URL.HOME);
      window.location.reload();
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const fetchUsers = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/users?page=${data.page || 1}&limit=${
        data.limit || 20
      }${data.name ? `&filter=${data.name}` : ""}${
        data.startDate ? `&start=${data.startDate || ""}` : ""
      }${data.endDate ? `&end=${data.endDate || ""}` : ""}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_USERS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const fetchSuperUsers = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/super-users?page=${data.page || 1}&limit=${
        data.limit || 20
      }${data.name ? `&filter=${data.name}` : ""}${
        data.startDate ? `&start=${data.startDate || ""}` : ""
      }${data.endDate ? `&end=${data.endDate || ""}` : ""}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_SUPER_USERS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const exportUsers = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .get(
      `${apiBaseUrl}/admin/users/export?filter=${data.name || ""}${
        data.startDate ? `&start=${data.startDate || ""}` : ""
      }${data.endDate ? `&end=${data.endDate || ""}` : ""}${
        data?.isSuperUser ? `&isSuperUser=true` : ``
      }`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { csv } = res.data;
      const a = document.createElement("a");
      a.href = csv;
      const fileName = csv.split("/").pop();
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(csv);
      a.remove();
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error);
    });
};

export const fetchSingleUser = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: FETCH_SINGLE_USER, payload: null });
  axios
    .get(`${apiBaseUrl}/admin/users/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      // console.log(res.data.user);
      dispatch({ type: FETCH_SINGLE_USER, payload: res.data.user });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const deactivateUser = (id, data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .put(`${apiBaseUrl}/admin/users/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      // console.log(res.data.user);
      dispatch({ type: FETCH_SINGLE_USER, payload: res.data.user });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const updatePassword = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/password/update`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      toast.success(res.data.message);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const addAdmin = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/adminusers`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      toast.success(res.data.message);
      history.push(PAGES_URL.ADMINS);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const getAdmins = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/adminusers?page=${data.page || 1}&limit=${
        data.limit || 20
      }&filter=${data.name || ""}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_ADMINS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const logoutAdmin = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  localStorage.clear();
  history.push(PAGES_URL.LOGIN);
};
