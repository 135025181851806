/** @format */

import React from "react";

const LogoutModal = () => {
  return (
    <div className="modal fade" id="logout">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content app-dark rounded-lg">
          <div className="modal-header d-flex justify-content-between">
            <h6
              className="modal-title text-light font-weight-bold"
              id="exampleModalLongTitle"
            >
              Are you sure to logout?
            </h6>
            <button
              type="button"
              className="close btn text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-red btn-auth px-4 py-2 font-weight-bold x-small"
              data-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-green btn-auth px-4 py-2 font-weight-bold x-small"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
