/** @format */

import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import PAGES_URL from "../routes/routes";
import {
  Login,
  Users,
  Sites,
  Admins,
  Settings,
  SingleUser,
  SingleSite,
  Requests,
  SingleRequest,
  AddAdmin,
  Coupons,
  Billings,
  SingleBilling,
  SiteVisits,
  Extensions,
  FailedPayments,
  FailedRegistrations,
  PaymentDetails,
  RegistrationDetails,
  UnconfiguredSSL,
  Subscriptions,
  UserGuides,
  EmailPricing,
  AuditTrails,
  FreeTrials,
  PendingComments,
  Referrers,
  Referrals,
} from "./pages";
import DashboardLayout from "../components/DashboardLayout";
import history from "../redux/actions/history";
import AuthRoute from "../routes/AuthRoute";
import UnAuthRoute from "../routes/UnAuthRoute";
import SuperUsers from "./dashboard/SuperUsers";

const Index = () => {
  return (
    <Router history={history}>
      <Switch>
        <UnAuthRoute exact path={PAGES_URL.LOGIN} component={Login} />
        <DashboardLayout>
          <AuthRoute exact path={PAGES_URL.USERS} component={Users} />
          <AuthRoute
            exact
            path={PAGES_URL.SUPER_USERS}
            component={SuperUsers}
          />
          <AuthRoute
            exact
            path={`${PAGES_URL.USERS}/:id`}
            component={SingleUser}
          />
          <AuthRoute exact path={PAGES_URL.SITES} component={Sites} />
          <AuthRoute
            exact
            path={PAGES_URL.SUBSCRIPTIONS}
            component={Subscriptions}
          />
          <AuthRoute exact path={PAGES_URL.EXTENSIONS} component={Extensions} />
          <AuthRoute
            exact
            path={PAGES_URL.SITE_VISITS}
            component={SiteVisits}
          />
          <AuthRoute
            exact
            path={PAGES_URL.FAILED_PAYMENTS}
            component={FailedPayments}
          />
          <AuthRoute
            exact
            path={PAGES_URL.UNCONFIGURED}
            component={UnconfiguredSSL}
          />
          <AuthRoute
            exact
            path={`${PAGES_URL.FAILED_PAYMENTS}/:id`}
            component={PaymentDetails}
          />
          <AuthRoute
            exact
            path={PAGES_URL.FAILED_REGISTRATIONS}
            component={FailedRegistrations}
          />
          <AuthRoute
            exact
            path={PAGES_URL.AUDIT_TRAIL}
            component={AuditTrails}
          />
          <AuthRoute
            exact
            path={`${PAGES_URL.FAILED_REGISTRATIONS}/:id`}
            component={RegistrationDetails}
          />
          <AuthRoute
            exact
            path={`${PAGES_URL.SITES}/:id`}
            component={SingleSite}
          />
          <AuthRoute exact path={PAGES_URL.BILLINGS} component={Billings} />
          <AuthRoute
            exact
            path={PAGES_URL.FREE_TRIALS}
            component={FreeTrials}
          />
          <AuthRoute
            exact
            path={`${PAGES_URL.BILLINGS}/:id`}
            component={SingleBilling}
          />
          <AuthRoute exact path={PAGES_URL.ADMINS} component={Admins} />
          <AuthRoute
            exact
            path={PAGES_URL.EMAIL_PRICING}
            component={EmailPricing}
          />
          <AuthRoute exact path={PAGES_URL.COUPONS} component={Coupons} />
          <AuthRoute exact path={PAGES_URL.ADD_ADMIN} component={AddAdmin} />
          <AuthRoute exact path={PAGES_URL.SETTINGS} component={Settings} />
          <AuthRoute exact path={PAGES_URL.REQUESTS} component={Requests} />
          <AuthRoute
            exact
            path={PAGES_URL.PENDING_COMMENTS}
            component={PendingComments}
          />
          <AuthRoute
            exact
            path={PAGES_URL.USER_GUIDES}
            component={UserGuides}
          />
          <AuthRoute
            exact
            path={`${PAGES_URL.REQUESTS}/:id`}
            component={SingleRequest}
          />
          <AuthRoute exact path={PAGES_URL.REFERRERS} component={Referrers} />
          <AuthRoute
            exact
            path={`${PAGES_URL.REFERRALS}/:id`}
            component={Referrals}
          />
        </DashboardLayout>
      </Switch>
    </Router>
  );
};

export default Index;
