/** @format */

import React, { Component, useState, useEffect } from "react";
import SideBar from "./SideBar";
import NavBar from "./NavBar";
import "../assets/styles/components/sidebar.scss";
import { connect } from "react-redux";
import { fetchDashboardData } from "../redux/actions";

// class DashboardLayout extends Component {
//   componentDidMount() {
//     this.props.loadData()
//     const menu = document.querySelector("#menu");
//     const sidebar = document.querySelector("#sidebar");
//     const modal = document.querySelector(".sidebar-modal");
//     modal.style.display = "none";
//     menu.addEventListener("click", () => {
//       sidebar.classList.remove("closer");
//       sidebar.classList.add("opener");
//       modal.style.display = "block";
//     });
//     modal.addEventListener("click", () => {
//       sidebar.classList.remove("opener");
//       sidebar.classList.add("closer");
//       modal.style.display = "none";
//     });
//   }

//   render() {
//     const { children } = this.props;
//     return (
//       <div className="vh-100 container-fluid bg-white">
//         <div className="row">
//           <SideBar />
//           <div className="col vh-10 px-0 page-container app-dark-5 text-dark">
//             <div className="pb-3">
//               <NavBar />
//               <div className="mt-4 px-4 px-xl-4 page-content">{children}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

const DashboardLayout = ({ children }) => {
  const [show, setShow] = useState(null);

  return (
    <div className="vh-100 container-fluid bg-white">
      <div className="row">
        <SideBar show={show} setShow={() => setShow(!show)} />
        <div className="col vh-10 px-0 page-container app-dark-5 text-dark">
          <div className="pb-3">
            <NavBar setShow={() => setShow(!show)} />
            <div className="mt-4 px-4 px-xl-4 page-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  loadData: () => dispatch(fetchDashboardData()),
});

export default connect(null, mapDispatch)(DashboardLayout);
