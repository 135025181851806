/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import PAGES_URL from "../routes/routes";

const SideBarNav = ({ icon, name, ...props }) => {
  return (
    <NavLink
      {...props}
      // eaxct={false}
      className="nav-link py-2 mb-1 d-flex align-items-center w-100"
      activeClassName="active-link"
    >
      <FeatherIcon
        icon={icon}
        strokeWidth={2}
        size="calc(18px + (18 - 18) * ((100vw - 375px) / (1440 - 375)))"
        className="mr-3"
      />
      <span className="text">{name}</span>
    </NavLink>
  );
};

const SideBar = ({ show, setShow }) => {
  return (
    <div className="vh-100">
      {show && (
        <div
          className="sidebar-modal vh-100 vw-100 position-absolute"
          onClick={() => setShow()}
        ></div>
      )}
      <div
        className={`h-100 sidebar shadow-sm pb-3 app-blue overflow-auto  ${
          show ? "opener" : "closer"
        }`}
        id="sidebar"
      >
        <div className="d-center align-items-end border-transparent py-3">
          <img
            src={
              "https://res.cloudinary.com/muzumedia/image/upload/v1677770659/logo-admin.svg"
            }
            alt=""
          />
          <h6 className="font-weight-bold px-1 ml-1 text-white mb-0">Admin</h6>
        </div>
        <div className="mt-3">
          <h6 className="font-weight-bold x-small px-3 text-white">
            DASHBOARD
          </h6>
          {/* <SideBarNav exact to={PAGES_URL.HOME} icon="home" name="Dashboard" /> */}
          <SideBarNav
            to={PAGES_URL.USERS}
            icon="user"
            name="Users"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.SUPER_USERS}
            icon="users"
            name="Super Users"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.SITES}
            icon="globe"
            name="Sites"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.SUBSCRIPTIONS}
            icon="tv"
            name="Subscriptions"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.SITE_VISITS}
            icon="truck"
            name="Site Visits"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.REFERRERS}
            icon="user"
            name="Referrers"
            onClick={() => setShow()}
          />
          {/* <SideBarNav
            to={PAGES_URL.REFERRALS}
            icon="users"
            name="Referrals"
            onClick={() => setShow()}
          /> */}
          <SideBarNav
            to={PAGES_URL.AUDIT_TRAIL}
            icon="x-circle"
            name="Audit trails"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.BILLINGS}
            icon="credit-card"
            name="Billings"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.FREE_TRIALS}
            icon="credit-card"
            name="Billings (Free Trials)"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.FAILED_REGISTRATIONS}
            icon="x-square"
            name="Failed Registrations"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.UNCONFIGURED}
            icon="cloud-off"
            name="Unconfigured SSL"
            onClick={() => setShow()}
          />
          <SideBarNav
            // exact
            to={PAGES_URL.COUPONS}
            icon="file-text"
            name="Coupons"
            onClick={() => setShow()}
          />
          <SideBarNav
            // exact
            to={PAGES_URL.REQUESTS}
            icon="phone"
            name="Requests"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.EXTENSIONS}
            icon="server"
            name="Extensions"
            onClick={() => setShow()}
          />
          <SideBarNav
            to={PAGES_URL.USER_GUIDES}
            icon="map"
            name="User Guides"
            onClick={() => setShow()}
          />
          <SideBarNav
            // exact
            to={PAGES_URL.EMAIL_PRICING}
            icon="at-sign"
            name="Email Pricing"
            onClick={() => setShow()}
          />
          <SideBarNav
            // exact
            to={PAGES_URL.ADMINS}
            icon="user-check"
            name="Admins"
            onClick={() => setShow()}
          />
          <SideBarNav
            exact
            to={PAGES_URL.SETTINGS}
            icon="settings"
            name="Settings"
            onClick={() => setShow()}
          />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
