/** @format */

import { createStore, applyMiddleware } from "redux";
import ThunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/rootReducer";
import { loadState } from "./localStorage";

const LoggerMiddleware = createLogger();

const middleWares =
  process.env.APP_ENV_MODE === "development" ||
  process.env.APP_ENV_MODE === "staging"
    ? applyMiddleware(LoggerMiddleware, ThunkMiddleware)
    : applyMiddleware(ThunkMiddleware);

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, middleWares);

export default store;
