/** @format */

import React from "react";
import FeatherIcon from "feather-icons-react";
import LogoutModal from "./Modals/LogoutModal";
import { connect } from "react-redux";
import { logoutAdmin } from "../redux/actions";

const NavBar = ({ logout, user, setShow }) => {
  return (
    <>
      <nav className="sticky bg-white rounded-l shadow-sm text-dark">
        <div className="py-3 py-md-2 px-3 px-xl-5 d-flex align-items-center page-content">
          <FeatherIcon
            icon="menu"
            strokeWidth={2}
            size={26}
            className="mr-2 pointer d-md-none"
            id="menu"
            onClick={() => setShow()}
          />
          <div className="ml-auto d-flex align-items-center">
            <FeatherIcon
              icon="bell"
              strokeWidth={2}
              size="calc(18px + (24 - 18) * ((100vw - 375px) / (1440 - 375)))"
              className="mr-2 mx-3"
            />
            <div>
              <p className="mb-0 font-weight-bold small">
                {user && user.firstName}
              </p>
              <p className="mb-0 small">{user && user.email}</p>
            </div>
            <img
              src="https://res.cloudinary.com/codeleaf/image/upload/v1607869270/admin-avatar.svg"
              className="avatar ml-3"
              alt=""
              data-toggle="dropdown"
            />
            <div className="dropdown-menu dropdown-menu-right bg-white shadow py-0">
              <button
                className="dropdown-item x-small dropdown-ads font-weight-bold py-2"
                type="button"
                // data-toggle="modal"
                // data-target="#logout"
                onClick={() => logout()}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* <LogoutModal /> */}
    </>
  );
};

const mapState = (state) => ({
  user: state.user.user,
});

const mapDispatch = (dispatch) => ({
  logout: () => dispatch(logoutAdmin()),
});

export default connect(mapState, mapDispatch)(NavBar);
