/** @format */

import React from "react";
import ReactPaginate from "react-paginate";

function Pagination({ totalPages, pageClick }) {
  return (
    <div className="d-flex justify-content-end flex-wrap mt-4">
      <ReactPaginate
        previousLabel={<i className="fas fa-chevron-left"></i>}
        nextLabel={<i className="fas fa-chevron-right"></i>}
        breakClassName="li"
        breakLinkClassName={"p-1 icon-edit mb-0 rounded mr-1 d-center"}
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={pageClick}
        containerClassName={"d-flex align-items-center paginate"}
        pageClassName="li font-weight-bold"
        pageLinkClassName="p-1 font-weight-bold icon-edit mb-0 rounded mr-1 d-center small"
        previousClassName="li"
        previousLinkClassName="icon-edit mb-0 rounded mr-1 d-center"
        nextClassName="li"
        nextLinkClassName="icon-edit mb-0 rounded mr-1 d-center"
        activeLinkClassName="bg-primary border-primary text-white"
      />
    </div>
  );
}

export default Pagination;
