/** @format */

import React from "react";

const Button = ({ className, children, loading, icon, ...props }) => {
  return (
    <button disabled={loading} className={`btn ${className}`} {...props}>
      {loading ? <i className="fas fa-spinner fa-spin"></i> : children}
    </button>
  );
};

export { Button };
