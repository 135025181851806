/** @format */

import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "react-moment";
import { Button } from "../../components/Button";
import { connect } from "react-redux";
import { exportUsers, fetchSuperUsers, fetchUsers } from "../../redux/actions";
import "../../assets/styles/components/table.scss";
import Pagination from "../../components/Pagination";
import SubscriptionCard from "../../components/SubscriptionCard";
import { Link } from "react-router-dom";
import PAGES_URL from "../../routes/routes";
import Loader from "../../components/Loader";
import range from "../../utils/dateRange";

const SuperUsers = ({ users, loadUsers, loading, exportData }) => {
  const limit = 15;
  const [name, setName] = useState("");
  const [pageState, setPageState] = useState({
    dateFrom: "",
    dateTo: "",
  });
  const [page, setPage] = useState(1);
  const { dateFrom, dateTo } = pageState;
  const [fetching, setFetching] = useState(null);

  useEffect(() => {
    loadUsers({ page: 1, limit, startDate: "", endDate: "" });
  }, []);

  const pageClick = (data) => {
    setPage(data.selected + 1);
    loadUsers({
      page: data.selected + 1,
      limit,
      name,
      startDate: pageState.dateFrom,
      endDate: pageState.dateTo,
    });
  };

  const handleCallback = (start, end, label) => {
    setPageState({
      dateFrom: start.format("YYYY-MM-DD"),
      dateTo: end.format("YYYY-MM-DD"),
    });
    loadUsers({
      page: 1,
      limit,
      name,
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD"),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loadUsers({ page: 1, limit, name, startDate: dateFrom, endDate: dateTo });
    // setName("");
  };

  return (
    <>
      <h5 className="font-weight-bold">
        Dashboard / <span className="text-blue x-small">Super Users</span>
      </h5>

      {!users ? (
        <Loader />
      ) : (
        <>
          <div className="row px-2 mt-3 mt-lg-4 d-flex flex-wrap">
            <SubscriptionCard value={users.totalRecords} text="total" />
          </div>
          <div className="mt-4 rounded py- px-2 mb-5">
            <form
              onSubmit={handleSubmit}
              className="form-auth ml-auto d-flex flex-wrap align-items-center col-12 col-md-12 col-lg-10 col-xl-9 col-xxl-7 px-0"
            >
              <div className="col-12 col-md-4 col-lg-5 d-flex align-items-center px-0 px-md-2 mb-3 mb-md-0">
                <DateRangePicker
                  onCallback={handleCallback}
                  initialSettings={{
                    ...range,
                  }}
                >
                  <input
                    type="text"
                    className="form-control form-border form-search"
                  />
                </DateRangePicker>
              </div>
              <div className="d-flex align-items-center col-md-6 col-lg-5 px-0">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control form-border form-search mb-3mb-md-0"
                  placeholder="Search by username"
                />

                <Button
                  disabled={loading}
                  loading={loading}
                  className="btn-primary ml-2"
                  style={{ minWidth: "90px" }}
                >
                  Search
                </Button>
              </div>
              <div className="col-6 col-md-2 px-0 mt-3 mt-md-0 pl-md-2">
                <Button
                  disabled={loading && fetching}
                  loading={loading && fetching}
                  onClick={() => {
                    setFetching(true);
                    exportData({
                      name,
                      startDate: pageState.dateFrom,
                      endDate: pageState.dateTo,
                      isSuperUser: true,
                    });
                  }}
                  type="button"
                  className="btn-primary w-100"
                  style={{ minWidth: "90px" }}
                >
                  Export
                </Button>
              </div>
            </form>
            <div className="py-1 mt-3 table-container shadowsm">
              <table className="w-100 table table-striped text-dark table-borderles">
                <thead>
                  <tr>
                    <th className="pl-xl-4">#</th>
                    <th>User ID</th>
                    <th className="no-break">User Name</th>
                    <th>Email</th>
                    <th>Country</th>
                    <th className="no-break">Sub Users</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.users &&
                    users.users.map((i, idx) => (
                      <tr key={idx} className="position-relative">
                        <td className="pl-xl-4">
                          {idx + 1 + (page - 1) * limit}
                        </td>
                        <td>{i._id}</td>
                        <td className="no-break">
                          {i.firstName} {i.lastName}
                        </td>
                        <td>{i.email}</td>
                        <td>{i.country}</td>
                        <td>{i?.subUsers?.length}</td>

                        <td>
                          <span
                            className={`app-${
                              i.deactivated ? "red" : "green"
                            } text-center px-3 py-1 text-${
                              i.deactivated ? "white" : "dark"
                            } rounded-lg`}
                          >
                            {i.deactivated ? "Deactivated" : "Active"}
                          </span>
                        </td>

                        <td className="no-break">
                          <Moment format="DD/MM/YYYY" withTitle>
                            {i.createdAt}
                          </Moment>
                        </td>
                        <td>
                          <Link to={`${PAGES_URL.USERS}/${i._id}`}>
                            <button className="btn p-0">
                              <i className="fas fa-eye"></i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination totalPages={users.totalPages} pageClick={pageClick} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapState = (state) => ({
  users: state.user.superUsers,
  loading: state.user.loading,
  data: state.data.dashboardData,
});

const mapDispatch = (dispatch) => ({
  loadUsers: (data) => dispatch(fetchSuperUsers(data)),
  exportData: (data) => dispatch(exportUsers(data)),
});

export default connect(mapState, mapDispatch)(SuperUsers);
