/** @format */

import {
  FETCH_SINGLE_USER,
  FETCH_SUPER_USERS,
  FETCH_USERS,
  LOADING,
  LOGIN_USER,
  LOGOUT,
} from "./types";

const INITIAL_STATE = {
  authenticated: null,
  user: null,
  loading: null,
  users: null,
  superUsers: null,
  singleUser: null,
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, authenticated: true, user: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    case FETCH_USERS:
      return { ...state, users: action.payload };
    case FETCH_SUPER_USERS:
      return { ...state, superUsers: action.payload };
    case FETCH_SINGLE_USER:
      return { ...state, singleUser: action.payload };
    case LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
